import { inject, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ApiService } from '@core/api/api.service';
import { catchError, finalize, map, tap } from 'rxjs';

import { Customer } from '../customer/customer.types';
import { CustomersStore } from './customers.store';
import { CustomersListResponse } from './customers.types';

@Injectable({
	providedIn: 'root',
})
export class CustomersService {
	readonly #store = inject(CustomersStore);
	#apiService = inject(ApiService);

	isLoading = signal<boolean>(false);

	getCustomer(customerId: string) {
		this.isLoading.set(true);

		return this.#apiService.get<Customer>(`/customer/${customerId}`).pipe(
			tap((data) => {
				this.#store.addCustomerDetail(data);
			}),
			finalize(() => {
				this.isLoading.set(false);
			}),
		);
	}

	getAllCustomers(access?: string) {
		this.isLoading.set(true);

		return toSignal(
			this.#apiService
				.get<CustomersListResponse>('/customer?page=1&numberPerPage=50', { params: { access } })
				.pipe(
					tap(() => this.isLoading.set(false)),
					map((data) => data.customers),
					catchError(() => {
						this.isLoading.set(false);
						throw new Error('Error getting customers list');
					}),
				),
		);
	}

	getWorkOrderByCustomer(customerId: string) {
		return this.#apiService
			.get<number[]>(`/workorder/getworkordersbycustomer/${customerId}`)
			.pipe(catchError(() => []));
	}

	updateCustomer(customerCode: string, customer: Record<string, unknown>) {
		return this.#apiService.put(`/customer/${customerCode}`, customer).pipe(
			catchError(() => {
				throw new Error('Error updating customer notification preferences');
			}),
		);
	}
}
